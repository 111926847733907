/** @jsx jsx */
import {jsx} from 'theme-ui';
import {globalStyles} from "../../../../src/@elegantstack/flow-ui-layout/Layout/Layout";
import {calculatorStyles, useInput} from "../../../../src/calculator_utils";

const styles = {
    litterSize: {
        fontWeight: 'bold'
    }
}

const DogLitterSizeCalculator = ({dogLitterSizes}) => {
        let [breedName, breedNameInput] = useInput({
            required: true,
            id: 'breedName',
            name: 'breedName',
        }, {
            options: dogLitterSizes.map(x => ({text: x.name, value: x.name}))
        });

        const selectedBreedData = dogLitterSizes.filter(x => x.name === breedName)?.[0];

        return (
            <div sx={{...globalStyles.p, ...calculatorStyles.root}}>
                <form sx={calculatorStyles.form} onSubmit={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                }}>
                    <div sx={{...calculatorStyles.formInputs, ' select': {marginBottom: '0px'}}}>
                        <label htmlFor="breedName">How Many Puppies Does Each Dog Breed Have?</label>
                        {breedNameInput}
                    </div>
                </form>

                {selectedBreedData
                    ?
                    <div sx={calculatorStyles.result}>
                        The {selectedBreedData.name} generally has around <span
                        sx={styles.litterSize}>{selectedBreedData.litter_size}</span> puppies in a litter.
                    </div>
                    : null
                }
            </div>
        );
    }
;

export default DogLitterSizeCalculator;
