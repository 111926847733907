import AdPlaceholder, { adsConfig } from "../../../../src/ads";
import { AmazonImageAndTextProductView } from "../../../../src/amazon";
import dogHeightWeightData from '../../../../src/dogBreedExplorer/dog_height_weight_data.json';
import DogCrateSizeCalculator from "../../../../content/posts/training_and_behavior/dog-crate-sizing-guide/DogCrateSizeCalculator";
import * as React from 'react';
export default {
  AdPlaceholder,
  adsConfig,
  AmazonImageAndTextProductView,
  dogHeightWeightData,
  DogCrateSizeCalculator,
  React
};