/** @jsx jsx */
import {jsx} from 'theme-ui';
import {globalStyles} from "../../../../src/@elegantstack/flow-ui-layout/Layout/Layout";
import {calculatorStyles, useInput} from "../../../../src/calculator_utils";

const styles = {
    weightResult: {
        fontWeight: 'bold'
    },
    pResult: {}
}

const seniorityOptions = [
    {value: 'Puppy', text: 'Puppy (~0-2 years old)'},
    {value: 'Adult', text: 'Adult (~2-5 years old)'},
    {value: 'Senior', text: 'Senior (5+ years old)'},
];
const DogWeightCarryCalculator = () => {
    let [dogSeniority, dogSeniorityInput] = useInput({
        required: true,
        id: 'seniorityLevel',
        name: 'seniorityLevel',
    }, {
        options: seniorityOptions, defaultOption: seniorityOptions[1]
    });
    let [dogWeight, dogWeightInput] = useInput({
        type: 'number',
        min: '0',
        required: true,
        id: 'weight',
        name: 'weight',
    });
    let [lowerLimit, upperLimit] = [undefined, undefined];

    if (dogSeniority && dogWeight) {
        if (dogSeniority === 'Adult') {
            lowerLimit = 0.2;
            upperLimit = 0.25;
        } else {
            lowerLimit = 0.1;
            upperLimit = 0.15;
        }
    }

    return (
        <div sx={{...globalStyles.p, ...calculatorStyles.root}}>
            <form sx={calculatorStyles.form} onSubmit={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
            }}>
                <div sx={calculatorStyles.formInputs}>
                    <label htmlFor="seniorityLevel">What is the seniority level of your dog?</label>
                    {dogSeniorityInput}

                    <label htmlFor="dogWeight">What is your dog's weight in pounds?</label>
                    {dogWeightInput}
                </div>
            </form>

            {dogSeniority && dogWeight
                ?
                <div sx={calculatorStyles.result}>
                    Your dog may be able to carry up to <span sx={styles.pResult}>{(lowerLimit * 100).toFixed(0)}</span>-<span
                    sx={styles.pResult}>{(upperLimit * 100).toFixed(0)}%</span> of their body weight, which is
                    between <br/><span
                    sx={styles.weightResult}>{(lowerLimit * dogWeight).toFixed(1)} lbs</span> to <span
                    sx={styles.weightResult}>{(upperLimit * dogWeight).toFixed(1)} lbs</span>
                </div>
                : null
            }
        </div>
    );
};

export default DogWeightCarryCalculator;
