/** @jsx jsx */
import {jsx} from 'theme-ui';
import moment from 'moment'
import {globalStyles} from "../../../../src/@elegantstack/flow-ui-layout/Layout/Layout";
import {calculatorStyles, useInput} from "../../../../src/calculator_utils";

const styles = {
    dueDate: {
        fontWeight: 'bold'
    }
}

const DogPregnancyCalculator = () => {
        let [breedingDate, breedingDateInput] = useInput({
            type: 'date',
            required: true,
            id: 'breedingDate',
            name: 'breedingDate',
        });
        let [lowerDueDate, upperDueDate] = [undefined, undefined];

        if (breedingDate) {
            breedingDate = moment(breedingDate);

            lowerDueDate = breedingDate.add(58, 'days').format('MMMM Do, YYYY')
            upperDueDate = breedingDate.add(72, 'days').format('MMMM Do, YYYY')
        }

        return (
            <div sx={{...globalStyles.p, ...calculatorStyles.root}}>
                <form sx={calculatorStyles.form} onSubmit={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                }}>
                    <div sx={calculatorStyles.formInputs}>
                        <label htmlFor="breedingDate">When did your dog first mate?</label>
                        {breedingDateInput}
                    </div>
                </form>

                {breedingDate
                    ?
                    <div sx={calculatorStyles.result}>
                        Your can expect your dog to give birth between <br/><span
                        sx={styles.dueDate}>{lowerDueDate}</span> to <span
                        sx={styles.dueDate}>{upperDueDate}</span>
                    </div>
                    : null
                }
            </div>
        );
    }
;

export default DogPregnancyCalculator;
