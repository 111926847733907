/** @jsx jsx */
import {jsx} from 'theme-ui';
import {OutboundLink} from "gatsby-plugin-google-gtag";

const styles = {
    link: {
        color: `alpha`,
        textDecoration: `none`,
        ':hover': {
            color: `alphaDark`
        },
        ':visited': {
            color: `alpha`
        },
    }
};

const ExternalLink = ({
                          href,
                          children,
                          paid,
                          target,
                          ...props
                      }) => {
    const rel = 'noopener' + (paid ? ' nofollow sponsored' : '');

    return (
        <OutboundLink sx={styles.link} href={href} rel={rel} target={target || '_blank'} {...props}>
            {children}
        </OutboundLink>
    );
};

export default ExternalLink;