export const affiliateProducts = {
    'midwest-homes-for-pets-dog-crate': 'https://www.amazon.com/MidWest-Folding-Divider-Protecting-Leak-Proof/dp/B000QFWCK0',
    'classic-kong': 'https://www.amazon.com/KONG-Classic-Durable-Natural-Rubber/dp/B000AYN7LU',
    'nylabone-power-chew-toy': 'https://www.amazon.com/Nylabone-Textured-Durable-Aggressive-Chewers/dp/B003SN5YKC',
    'durable-benebone-chew-toy': 'https://www.amazon.com/Benebone-Wishbone-Durable-Aggressive-Chewers/dp/B00CPDWT2M',
    'smartpetlove-heartbeat-toy': 'https://www.amazon.com/SmartPetLove-Snuggle-Puppy-Behavioral-Golden/dp/B000S753WK',
    'nylabone-ring-toy': 'https://amazon.com/Nylabone-Textured-Flavor-Medley-Regular/dp/B084LMXSP7',
    'simple-solution-pet-stain-and-odor-remover': 'https://www.amazon.com/Simple-Solution-Enzymatic-Pro-Bacteria-Cleaning/dp/B0002APQVQ',
    'amazonbasics-regular-puppy-training-pads': 'https://www.amazon.com/AmazonBasics-Regular-Puppy-Training-Pads/dp/B00MW8G62E',
    'midwest-exercise-pen': 'https://www.amazon.com/dp/B002908DX0',
    'taste-of-the-wild-pacific-stream-for-growing-puppies': 'https://www.amazon.com/Taste-Wild-Pacific-Protein-Natural/dp/B07KNLWMWX/',
    'mlife-dog-bowls': 'https://www.amazon.com/Mlife-Stainless-Rubber-Feeder-Perfect/dp/B01MY3D0KR/',
    'upsky-dog-water-bowl': 'https://www.amazon.com/UPSKY-Floating-Dispenser-Anti-Overflow-Fountain/dp/B07J4PJNZF/',
    'rabbitgo-dog-harness': 'https://www.amazon.com/Rabbitgoo-Harness-Adjustable-Reflective-Material/dp/B01M8JT6FT/',
    'baapet-strong-dog-leash': 'https://www.amazon.com/Strong-Dog-Comfortable-Reflective-Threads/dp/B078MYFT8D/',
    'richell-paw-trax-mesh-training-tray': 'https://www.amazon.com/Richell-Trax-Mesh-Training-Brown/dp/B00MGTQSA4/',
    'bounty-paper-towels': 'https://www.amazon.com/dp/B010OW4KMW',
    'earth-rated-dog-wipes': 'https://www.amazon.com/Earth-Rated-USDA-Certified-Hypoallergenic-Lavender-Scented/dp/B07NHGX19F/',
    'furtime-durable-dog-bed': 'https://www.amazon.com/FURTIME-Washable-Breathable-Rectangle-Anti-Slip/dp/B08GYF3MP4/',
    'petstages-deer-antler': 'https://www.amazon.com/Petstages-Antler-Replacement-Deerhorn-Large/dp/B016OHRJOM/',
    'hertzko-self-cleaning-brush': 'https://www.amazon.com/Hertzko-Self-Cleaning-Slicker-Brush/dp/B00ZGPI3OY/',
    'boshel-dog-nail-clipper': 'https://www.amazon.com/Dog-Nail-Clippers-Trimmer-Boshel/dp/B00UJ2WLB0/',
    'beswit-dog-rope-toys-pack': 'https://www.amazon.com/gp/product/B08CMPLDX8/',
    'earth-rated-dog-poop-bags': 'https://www.amazon.com/Earth-Rated-Unscented-Completely-Inches/dp/B00LAI9OM6/',
    'wahl-gentle-puppy-shampoo': 'https://www.amazon.com/dp/B005CUTY7I',
    'paw-lifestyles-dog-treat-training-pouch': 'https://www.amazon.com/dp/B01DN07JUM',
    'ehunt-dog-training-clicker': 'https://www.amazon.com/Ehunt-Training-Clicker-Clickers-Wrist/dp/B08JTS3DJ3/',
    'hicaptain-polyester-dog-crate-cover': 'https://www.amazon.com/HiCaptain-Polyester-Windproof-Provided-Protection/dp/B07GWH682Y/',
    'paw-inspired-disposable-dog-dipaers-female-dogs': 'https://www.amazon.com/Paw-Inspired-Protection-Disposable-Diapers/dp/B073NYN74F',
    'farnam-repel-x': 'https://www.amazon.com/Farnam-Repel-X-Insecticide-Repellent-Horses/dp/B000B6EHR0',
    'surgical-recovery-clothing': 'https://www.amazon.com/s?k=Surgical+recovery+clothing',
    'inflatable-collars': 'https://www.amazon.com/s?k=Inflatable+collars',
    'soft-e-collars': 'https://www.amazon.com/s?k=Soft+e-collars',
    'the-comfy-cone': 'https://www.amazon.com/Original-Comfy-Recovery-Collar-Removable/dp/B000XY7CKE',
    'neck-control-collars': 'https://www.amazon.com/BiteNot-BN6-Collar-27/dp/B001VPCF60/?th=1',
    'deshedding-shampoos': 'https://www.amazon.com/s?k=deshedding+shampoo',
    'rubber-brushes': 'https://www.amazon.com/customerpicks/Explore-rubber-brushes-for-dogs/858be0cc1498351f8f86',
    'burts-bees-dog-conditioner': 'https://www.amazon.com/Burts-Bees-Dogs-Conditioner-Colloidal/dp/B00CVTROQ6/',
    'dog-winter-clothing': 'https://www.amazon.com/s?k=dog+winter+clothing',
    'bedsure-calming-dog-bed': 'https://www.amazon.com/gp/product/B08CXGYW1Q',
    'dog-backpacks': 'https://www.amazon.com/s?k=dog+backpacks',
    'onetigris-dog-pack': 'https://www.amazon.com/OneTigris-Travel-Camping-Backpack-Rucksack/dp/B00MQGL712/',
    'bums-pack-dog-saddle-bag': 'https://www.amazon.com/Bums-Pack-Backpack-Harness-Reflector/dp/B07YXQYP12/',
    'wellver-dog-backpack': 'https://www.amazon.com/Wellver-Backpacks-Outdoor-Walking-Camping/dp/B073WWGK9B/',
    'excellent-elite-spanker-dog-pack': 'https://www.amazon.com/EXCELLENT-ELITE-SPANKER-Backpack-Capacious/dp/B07Q5HRJHP/',
    'k9-sport-sack': 'https://www.amazon.com/K9-Sport-Sack-Adjustable-Veterinarian/dp/B06X3XJ3BK/',
    'pawaboo-pet-carrier-backpack': 'https://www.amazon.com/PAWABOO-Backpack-Adjustable-Easy-Fit-Traveling/dp/B01KNULJLU/',
    'lekesky-dog-carrier-backpack': 'https://www.amazon.com/Lekesky-Carrier-Backpack-Expandable-Foldable/dp/B08XY1SC8Q/',
    'woyyho-pet-dog-carrier-backpack': 'https://www.amazon.com/WOYYHO-Carrier-Backpack-Breathable-Head-Out/dp/B08L7RFYXG/',
    'naturvet-yard-odor-eliminator': 'https://www.amazon.com/NaturVet-Eliminator-Eliminate-Designed-Concrete/dp/B0714K3YX6/',
    'simple-green-outdoor-odor-eliminator': 'https://www.amazon.com/Simple-Outdoor-Eliminator-Gallon-Refill/dp/B0098QN9SC/',
    'furhaven-pet-deluxe-two-tone-sofa-style': 'https://www.amazon.com/Furhaven-Pet-Deluxe-Two-Tone-Sofa-Style/dp/B0773N5Q9Y',
    'black-decker-pet-dustbuster': 'https://www.amazon.com/BLACK-DECKER-dustbuster-AdvancedClean-HHVK515JP07/dp/B07R89PJY6',
    'petlovers-lint-rollers-pet-hair': 'https://www.amazon.com/PetLovers-Rollers-Extra-Sticky-Sheets/dp/B0855ND74T',
    'gorilla-tape': 'https://www.amazon.com/Gorilla-Tape-Black-Duct-1-88/dp/B01MCZCLCQ',
    'vgo-rubber-gloves': 'https://www.amazon.com/Vgo-Household-Dishwashing-Thickness-Gardening/dp/B085SZ3GJH',
    'utopp-balloons': 'https://www.amazon.com/UTOPP-Balloons-Turquoise-Christmas-Decoration/dp/B01MDOXV0Z',
    'lilly-brush-min-pet-hair-detailer': 'https://www.amazon.com/Lilly-Brush-Mini-Hair-Detailer/dp/B0764MC2ZH',
    'bounce-free-gentle-dryer-sheets': 'https://www.amazon.com/Bounce-Fabric-Softener-Sheets-Gentle/dp/B003CP14R8',
    'the-caveman-cleaning-stone': 'https://www.amazon.com/Pet-Fur-Lint-Remover-Furniture/dp/B07F18TB1Y',
    'squeeqee-desired-tools': 'https://www.amazon.com/Squeegee-Shower-Window-Car-Glass/dp/B01M3O39UJ',
    'nylabone-chill-and-chew-toy': 'https://www.amazon.com/Nylabone-Puppy-Freezer-Apple-Flavor/dp/B07MG8P75V/',
    'heated-pet-bed': 'https://www.amazon.com/Pet-Products-1913-Thermo-Snuggly-Sleeper/dp/B07HMP397M',
    'outward-hound-fun-feeder-slo-bowl': 'https://www.amazon.com/Outward-Hound-Feeder-Bloat-Orange/dp/B00FPKNRCS/',
    'embark-dna-test': 'https://shareasale.com/r.cfm?b=1392287&m=66364',
    'embark-dna-and-health-test': 'https://shareasale.com/r.cfm?b=1751095&m=66364',
    'briotech-sanitizer': 'https://www.amazon.com/BRIOTECH-Sanitizer-Disinfectant-Hypochlorous-Non-Living/dp/B094YWT673/',
    'wd40': 'https://www.amazon.com/WD-40-Multi-Use-Product-Big-Blast-Lubricant/dp/B00HEVD9GU/',
    'bio-dog': 'https://www.amazon.com/Bio-Active-Stain-Odor-Remover/dp/B07QN8LSM7/',
    'petsafe-wee-care-enzyme-cleaner': 'https://www.amazon.com/PetSafe-Whiff-Off-Odor-Eliminator/dp/B002RL9E5C/',
    'activated-charcoal': 'https://www.amazon.com/Organic-Activated-Charcoal-1-8-Whitening/dp/B08GYHCC1S/',
    'only-natural-pet-stain-odor-remover': 'https://www.amazon.com/Only-Natural-Pet-Stain-Remover/dp/B01DBZSOGM/',
    'tropiclean-shampoo': 'https://www.amazon.com/TropiClean-Papaya-Coconut-Shampoo-Conditioner/dp/B002GPD8BU/',
    'k9-pro-aloe-and-oatmeal-cucumber-melon-shampoo': 'https://www.amazon.com/Oatmeal-Dog-Shampoo-Conditioner-Hypoallergenic/dp/B074GLQ8XP/',
    'paw-and-pals-oatmeal-dog-shampoo': 'https://www.amazon.com/Natural-Oatmeal-Dog-Shampoo-Conditioner-Medicated/dp/B0167AVKCI/',
    'cheddar-caramel-chicago-popcorn': 'https://www.amazon.com/Signature-Popcorn-Gourmet-1-Gallon-Reusable/dp/B072JLWYXR/',
    'baking-soda': 'https://www.amazon.com/Arm-Hammer-Pure-Baking-Shaker/dp/B006X1BQWG/',
    'midwest-wire-crate-22': 'https://www.amazon.com/dp/B0002TKBU8/',
    'midwest-wire-crate-24': 'https://www.amazon.com/dp/B000HCLDFC/',
    'midwest-wire-crate-30': 'https://www.amazon.com/dp/B0002TKBUS/',
    'midwest-wire-crate-36': 'https://www.amazon.com/dp/B0002AT3M4/',
    'midwest-wire-crate-42': 'https://www.amazon.com/dp/B0002AT3ME/',
    'midwest-wire-crate-48': 'https://www.amazon.com/dp/B0002AT3MO/',
    'midwest-wire-crate-54': 'https://www.amazon.com/dp/B005VVWBVY/',
    'petsfit-portable-soft-collapsible-dog-crate': 'https://www.amazon.com/Petsfit-Portable-Medium-Indoor-Outdoor/dp/B08SJ9J19X/',
    'petmate-vari-kennel': 'https://www.amazon.com/Petmate-Kennel-Heavy-Duty-No-Tool-Assembly/dp/B00DJRA0UQ/',
    'the-casual-home-pet-crate': 'https://www.amazon.com/Casual-Home-600-41-Wooden-Crate/dp/B0195A5ZXM/',
    '4x4-north-america-variocage': 'https://www.amazon.com/MIM-Variocage-Single-Tested-Travel/dp/B00LZ0NIW6/',
    'kuntrona-break-stick': 'https://www.amazon.com/Kuntrona-Pitbull-Professional-Training-Shepherd/dp/B07FZMXFM7/',
    'noyal-slow-feeder': 'https://www.amazon.com/Noyal-Feeder-Puzzle-Anti-Gulping-Feeding/dp/B07G21F45T/',
    'thundershirt-anxiety-jacket': 'https://www.amazon.com/Thundershirt-Dog-Anxiety-Treatment-Medium/dp/B0029PYC3K/',
    'risepro-decibel-meter': 'https://www.amazon.com/RISEPRO-Decibel-Meter-Digital-Sound/dp/B01EZZ8B5Q/',
    'vittles-vault': 'https://www.amazon.com/Vittles-Vault-Stackable-Airtight-Container/dp/B0002H3S5K/',
    'metro-vacuum-crate-fan': 'https://www.amazon.com/Metro-Vacuum-Crate-Cooling-CCF-1/dp/B00008435E/',
    'cool-pet-mat': 'https://www.amazon.com/Green-Shop-Cooling-Patented-Pressure-Activated/dp/B006NONHNE/',
    'be-cool-solutions-reflective-aluminet': 'https://www.amazon.com/Be-Cool-Solutions-Reflective-Aluminet/dp/B01BD21W1E/',
    'amazon-basics-foldable-metal-playpen': 'https://www.amazon.com/AmazonBasics-Foldable-Metal-Exercise-Fence/dp/B0758CTVVR/',
    'midwest-homes-crate-cover': 'https://www.amazon.com/Midwest-Homes-Pets-Crate-Cover/dp/B074RNPL2M/',
    'guardians-removable-stainless-feeder': 'https://www.amazon.com/Guardians-Removable-Stainless-Feeder-Flower/dp/B07V1QPVP7/',
    'ray-allen-bb1-neu-buddy-bowl': 'https://www.amazon.com/Ray-Allen-BB1-NEU-Buddy-Neutral/dp/B01IMQFL02/',
    'lixit-top-fill-no-drip-water-bottle': 'https://www.amazon.com/Lixit-NO-Drip-Water-Bottles-Dogs/dp/B004HSUL46/',
    'naturvet': 'https://www.amazon.com/NaturVet-Bitter-Chewing-Furniture-Formula/dp/B000EI1BRO/',
    'beaphar-lactol-puppy-replacer-powder': 'https://www.amazon.com/Beaphar-Lactol-Puppy-Replacer-Powder/dp/B07T1FT5CN/',
    'blue-buffalo-life-protection-formula': 'https://www.amazon.com/Blue-Buffalo-Protection-Formula-Puppy/dp/B000XKCDNE/',
    'purina-pro-plan': 'https://www.amazon.com/Purina-Pro-Plan-Probiotics-Sensitive/dp/B0841HCYM1/',
    'naturvet-chew-deterrent': 'https://www.amazon.com/NaturVet-Bitter-Chewing-Furniture-Formula/dp/B000EI1BRO/',
    'termometro-digital-thermometer': 'https://www.amazon.com/Best-Digital-Medical-Thermometer-Termometro/dp/B07BDFZCSV/',
    'potty-attractant-spray': 'https://www.amazon.com/OUT-Attractant-Indoor-Outdoor-Training/dp/B0091RE52C/',
    'busy-buddy-tug-a-jug': 'https://www.amazon.com/PetSafe-Buddy-Meal-Dispensing-Kibble-Treats/dp/B000KV7ZGQ/',
    'idepet-dog-toy-ball': 'https://www.amazon.com/Idepet-Nontoxic-Resistant-Cleaning-Exercise/dp/B073DZSVYF/',
    'outpets-iq-treat-ball': 'https://www.amazon.com/OurPets-Interactive-Dispensing-Assorted-Colors/dp/B003ARUKU0/',
    'nylabone-peanut-butter': 'https://www.amazon.com/Nylabone-DuraChew-Peanut-Butter-X-Large/dp/B00JZIDFGO/',
    'friendly-no-rise-waterless-shampoo': 'https://www.amazon.com/Friendly-No-Rinse-Waterless-Shampoo-Coconut/dp/B00GZQYLOE/',
    'adaptil-dog-calming-pheromone-diffuser': 'https://www.amazon.com/CEVA-Animal-Health-ADAPTIL-Diffuser/dp/B01AW71ILU/',
    'petmate-sky-kennel': 'https://www.amazon.com/Petmate-Kennel-Portable-Travel-Included/dp/B003WQTT1A/',
    'voyager-step-air-dog-harness': 'https://www.amazon.com/Voyager-Step-Air-Dog-Harness/dp/B092JRGLCZ/',
    'panykoo-strong-pet-dog-leash': 'https://www.amazon.com/Panykoo-Reflective-Threads-Comfortable-Suitable/dp/B08XVR2MYJ/',
    'lamb-liver-by-stewart': 'https://www.amazon.com/Stewart-401824-Freeze-Dried-Treats/dp/B00MHLO8P8/',
    'bedsure-waterproof-dog-blanket': 'https://www.amazon.com/Bedsure-Waterproof-Blanket-Small-Dogs/dp/B08CX74328/',
    'spill-proof-pet-bowl': 'https://www.amazon.com/Franklin-Pet-Supply-Travel-Silicon/dp/B07JQBXPLJ/',
    'amazonbasics-enhanced-poop-bags': 'https://www.amazon.com/AmazonBasics-Enhanced-Waste-Dispenser-Leash/dp/B076VSDJY1/',
    'juanliapc-interacting-feeding': 'https://www.amazon.com/JUANLIAPC-Interactive-Feeding-Training-Enrichment/dp/B0B3TDQ7H3/',
    'dog-ball-treat-dispenser': 'https://www.amazon.com/Dispensing-Aggressive-Chewers-Indestructible-Squeaky/dp/B09PR6MDSX/',
    'treat-dispensing-dog-toys-caterpillar': 'https://www.amazon.com/Treat-Dispensing-Dog-Toys-Caterpillar/dp/B095LW6KMK/',
    'ottoson-outward-hound-brick-puzzle': 'https://www.amazon.com/Ottoson-Outward-Hound-Brick-Puzzle/dp/B0711Y9XTF/',
    'the-intelligence-of-dogs-book': 'https://www.amazon.com/Intelligence-Dogs-Stanley-Coren/dp/1416502874',
    'sunton-deterrent': 'https://www.amazon.com/SUNTON-Deterrent-Training-Corrector-Furniture/dp/B0B31G12T7/',
    'rocco-and-roxie-no-chew-spray': 'https://www.amazon.com/Rocco-Roxie-Extreme-Bitter-Spray/dp/B06XCZ8KJJ/'
};
