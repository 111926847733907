import React from 'react'

export const CustomList = ({items, bulletStyle = '•', itemMarginLeft = '5px'}) => {
    return (
        <ul style={{
            listStyle: `"${bulletStyle}"`,
            listStylePosition: 'outside',
            padding: '0',
            margin: '0',
            marginBottom: '2rem',
            listStyleImage: 'none',
            marginLeft: '1rem'
        }}>
            {items.map(item => (
                <li key={item}
                    style={{
                        marginBottom: '0.5rem',
                        paddingLeft: itemMarginLeft,
                        color: 'var(--theme-ui-colors-article,#4a5568)',
                        fontSize: '1.25rem',
                        margin: 0
                    }}>
                    {item}
                </li>
            ))}
        </ul>
    );
};
