import React from 'react'

export const adsConfig = {
    post: {
        topOfPage: {
            name: 'Post: Top of Page',
            type: 'top_of_page',
            id: 'ezoic-pub-ad-placeholder-144'
        },

        bottomOfPage: {
            name: 'Post: Bottom of Page',
            type: 'bottom_of_page',
            id: 'ezoic-pub-ad-placeholder-150'
        },
        sidebarTop: {
            name: 'Post: Sidebar Top',
            type: 'sidebar',
            id: 'ezoic-pub-ad-placeholder-146'
        },
        sidebarBottom: {
            name: 'Post: Sidebar Bottom',
            type: 'sidebar_bottom',
            id: 'ezoic-pub-ad-placeholder-148'
        },
        underFirstParagraph: {
            name: 'Post: Under First Paragraph',
            type: 'under_first_paragraph',
            id: 'ezoic-pub-ad-placeholder-154'
        },
        underSecondParagraph: {
            name: 'Post: Under Second Paragraph',
            type: 'under_second_paragraph',
            id: 'ezoic-pub-ad-placeholder-155'
        },
        inContent1: {
            name: 'Post: In Content 1',
            type: 'mid_content',
            id: 'ezoic-pub-ad-placeholder-156'
        },
        inContent2: {
            name: 'Post: In Content 2',
            type: 'long_content',
            id: 'ezoic-pub-ad-placeholder-157'
        },
        inContent3: {
            name: 'Post: In Content 3',
            type: 'longer_content',
            id: 'ezoic-pub-ad-placeholder-158'
        },
        inContent4: {
            name: 'Post: In Content 4',
            type: 'longest_content',
            id: 'ezoic-pub-ad-placeholder-159'
        },
        inContent5: {
            name: 'Post: In Content 5',
            type: 'incontent_5',
            id: 'ezoic-pub-ad-placeholder-160'
        },
        inContent6: {
            name: 'Post: In Content 6',
            type: 'incontent_6',
            id: 'ezoic-pub-ad-placeholder-161'
        },
        inContent7: {
            name: 'Post: In Content 7',
            type: 'incontent_7',
            id: 'ezoic-pub-ad-placeholder-162'
        },
        inContent8: {
            name: 'Post: In Content 8',
            type: 'incontent_8',
            id: 'ezoic-pub-ad-placeholder-163'
        },
        inContent9: {
            name: 'Post: In Content 9',
            type: 'incontent_9',
            id: 'ezoic-pub-ad-placeholder-164'
        },
        inContent10: {
            name: 'Post: In Content 10',
            type: 'incontent_10',
            id: 'ezoic-pub-ad-placeholder-165'
        },
        inContent11: {
            name: 'Post: In Content 11',
            type: 'incontent_11',
            id: 'ezoic-pub-ad-placeholder-166'
        },
        inContent12: {
            name: 'Post: In Content 12',
            type: 'incontent_12',
            id: 'ezoic-pub-ad-placeholder-167'
        },
        inContent13: {
            name: 'Post: In Content 13',
            type: 'incontent_13',
            id: 'ezoic-pub-ad-placeholder-168'
        },
        inContent14: {
            name: 'Post: In Content 14',
            type: 'incontent_14',
            id: 'ezoic-pub-ad-placeholder-169'
        },
        inContent15: {
            name: 'Post: In Content 15',
            type: 'incontent_15',
            id: 'ezoic-pub-ad-placeholder-170'
        },
        inContent16: {
            name: 'Post: In Content 16',
            type: 'incontent_16',
            id: 'ezoic-pub-ad-placeholder-171'
        },
        inContent17: {
            name: 'Post: In Content 17',
            type: 'incontent_17',
            id: 'ezoic-pub-ad-placeholder-172'
        },
        inContent18: {
            name: 'Post: In Content 18',
            type: 'incontent_18',
            id: 'ezoic-pub-ad-placeholder-173'
        },
        inContent19: {
            name: 'Post: In Content 19',
            type: 'incontent_19',
            id: 'ezoic-pub-ad-placeholder-174'
        },
        inContent20: {
            name: 'Post: In Content 20',
            type: 'incontent_20',
            id: 'ezoic-pub-ad-placeholder-175'
        },
    },
    home: {
        sidebarTop: {
            name: 'Home: Sidebar Top',
            type: 'sidebar',
            id: 'ezoic-pub-ad-placeholder-151'
        },
        sidebarBottom: {
            name: 'Home: Sidebar Bottom',
            type: 'sidebar_bottom',
            id: 'ezoic-pub-ad-placeholder-152'
        },
        bottomOfPage: {
            name: 'Home: Bottom of Page',
            type: 'bottom_of_page',
            id: 'ezoic-pub-ad-placeholder-153'
        },
    }
}

const AdPlaceholder = ({name, type, id}) => {
    return (
        <div className="ad-placeholder"
             style={{
                 width: '100%',
                 display: 'flex',
                 alignContent: 'center',
                 justifyContent: 'center',
                 alignItems: 'center',
                 justifyItems: 'center',
             }}
             dangerouslySetInnerHTML={{
                 __html:
                     `<!-- Ezoic - ${name} - ${type} -->
                      <div id=${id}></div>
                      <!-- End Ezoic - ${name} - ${type} -->`
             }}
        />
    );
};

export const videoAdsConfig = {
    whyDoesMyDogLickMyUnderwear: {
        id: 'ezoic-pub-video-placeholder-1'
    },
    dogActingStrangeAfterGrooming: {
        id: 'ezoic-pub-video-placeholder-2'
    },
    isYourDogAWolf: {
        id: 'ezoic-pub-video-placeholder-3'
    },
}

export const VideoAdPlaceholder = React.memo(({id}) =>
    <div
        className="video-ad-placeholder"
        style={{
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            justifyItems: 'center',
        }}
        dangerouslySetInnerHTML={{
            __html:
                `<span id=${id}></span>`

        }}
    >
    </div>);

export default React.memo(AdPlaceholder);
