export default {
  variant: `links.mute`,
  fontWeight: `normal`,
  mb: 2,
  p: 0,
  svg: {
    verticalAlign: `middle`,
    display: `inline-block`,
    mr: 2,
    mb: 0
  }
}
