import React, {useEffect} from "react";
import {affiliateProducts} from "./affiliateProducts";

export const amazonAssociatesTag = process.env.GATSBY_AMAZON_ASSOCIATES_TAG;
export const amazonAssociatesNativeAdsInstanceId = process.env.GATSBY_AMAZON_ASSOCIATES_NATIVE_ADS_INSTANCE_ID

export const AmazonNativeAdsPlaceholder = React.memo(({instanceId = amazonAssociatesNativeAdsInstanceId}) => {
    useEffect(() => {
        const script = document.createElement("script");

        script.src = `//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=${instanceId}`;
        script.defer = true;

        document.body.appendChild(script);
    }, [instanceId]);

    return (
        <div id={`amzn-assoc-ad-${instanceId}`}/>
    );
}, (prevProps, nextProps) => true);

export const getProductIdFromProductLink = productLink => {
    let re = /.*\/dp\/(?<productId>\w+)\/?.*/
    let match = productLink.match(re);
    if (match == null || match.groups === undefined) {
        re = /.*\/gp\/product\/(?<productId>\w+)\/?.*/
        match = productLink.match(re);
        if (match == null || match.groups === undefined) {
            return undefined;
        }
    }

    return match.groups.productId
};

export const ProductListContainer = ({children}) =>
    <div style={{
        display: "flex",
        direction: "column",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        alignContent: "space-evenly"
    }}>
        {children}
    </div>;

export const AmazonImageAndTextProductView = ({
                                                  product,
                                                  linkId = '',
                                                  openInNewWindow = true,
                                                  showBorder = true,
                                                  marketplace = 'US',
                                                  serviceVersion = 20070822,
                                                  adType = 'product_link',
                                                  language = 'en_US',
                                                  trackingId = amazonAssociatesTag,
                                                  padTop = false,
                                                  padBottom = true
                                              }) => {
    if (product === undefined || !(product in affiliateProducts)) {
        return <div></div>
    }

    const productLink = affiliateProducts[product];
    const productId = getProductIdFromProductLink(productLink);
    if (productId === undefined) {
        return <div></div>
    }

    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: padTop ? '25px' : '0px',
        paddingBottom: padBottom ? '25px' : '0px',
    }}>
        <iframe title={product}
                key={product}
                style={{width: '120px', height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no"
                frameBorder="0"
                src={`//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=${serviceVersion}&OneJS=1&Operation=GetAdHtml&MarketPlace=${marketplace}&source=ss&ref=as_ss_li_til&ad_type=${adType}&tracking_id=${trackingId}&language=${language}&marketplace=amazon&region=US&placement=${productId}&asins=${productId}&linkId=${linkId}&show_border=${showBorder ? "true" : "false"}&link_opens_in_new_window=${openInNewWindow ? "true" : "false"}`}></iframe>
    </div>
};

export const AmazonImageProductView = ({
                                           product,
                                           linkId = '',
                                           marketplace = 'US',
                                           serviceVersion = 20070822,
                                           language = 'en_US',
                                           size = 'large',
                                           trackingId = amazonAssociatesTag,
                                           encoding = 'UTF8',
                                           ws = 1,
                                           padTop = false,
                                           padBottom = true
                                       }) => {
    if (product === undefined || !(product in affiliateProducts)) {
        return <div></div>
    }

    const productLink = affiliateProducts[product];
    const productId = getProductIdFromProductLink(productLink);
    if (productId === undefined) {
        return <div></div>
    }

    let format;
    if (size === 'small') {
        format = '_SL110_';
    } else if (size === 'medium') {
        format = '_SL160_';
    } else {
        format = '_SL250_';
    }

    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: padTop ? '25px' : '0px',
        paddingBottom: padBottom ? '25px' : '0px',
    }}>
        <a href={`${productLink}?&linkCode=li3&tag=${trackingId}&linkId=${linkId}&language=${language}`}
           target="_blank">
            <img border="0"
                 src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=${encoding}&ASIN=${productId}&Format=${format}&ID=AsinImage&MarketPlace=${marketplace}&ServiceVersion=${serviceVersion}&WS=${ws}&tag=${amazonAssociatesTag}&language=${language}`}
            />
        </a>
        <img
            src={`https://ir-na.amazon-adsystem.com/e/ir?t=${trackingId}&language=${language}&l=li3&o=1&a=${productId}`}
            width="1" height="1" border="0" alt=""
            style={{border: 'none !important', margin: '0px !important'}}/>
    </div>
};
