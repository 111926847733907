import AdPlaceholder, { adsConfig } from "../../../../src/ads";
import { AmazonImageAndTextProductView } from "../../../../src/amazon";
import DogWeightCarryCalculator from "../../../../content/posts/dog_ownership_and_care/how-much-can-a-dog-carry/weight-carry-calculator";
import * as React from 'react';
export default {
  AdPlaceholder,
  adsConfig,
  AmazonImageAndTextProductView,
  DogWeightCarryCalculator,
  React
};