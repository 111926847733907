const white = '#fff'
const gray = {
    200: '#edf2f7',
    300: '#e2e8f0',
    400: '#cbd5e0',
    500: '#a0aec0',
    600: '#718096',
    700: '#4a5568',
    800: '#2d3748',
    900: '#1a202c'
}

const orange = {
    100: '#fffaf0',
    200: '#feebc8',
    300: '#fbd38d',
    400: '#f6ad55',
    500: '#ed8936',
    600: '#dd6b20',
    700: '#c05621',
    800: '#9c4221',
    900: '#7b341e'
}

const green = {
    100: '#f0fff4',
    500: '#48bb78'
}

const red = {
    100: '#fff5f5',
    500: '#f56565'
}

const blue = {
    100: '#ebf8ff',
    200: '#bee3f8',
    300: '#90cdf4',
    400: '#63b3ed',
    500: '#4299e1',
    600: '#3182ce',
    700: '#2b6cb0',
    800: '#2c5282',
    900: '#2a4365'
}

//Primary
const
    alpha = {
        alphaLighter: orange[100],
        alphaLight: orange[300],
        alpha: orange[500],
        alphaDark: orange[700],
        alphaDarker: orange[900],
    }

// Secondary
const beta = {
    betaLighter: '#E4CAB4',
    betaLight: '#DAB495',
    beta: '#CF9E76',
    betaDark: '#C58959',
    betaDarker: '#B57540',
}

// Reserved
const gamma = {
    gammaLighter: '#B0E8C5',
    gammaLight: '#90DFAE',
    gamma: '#68D391',
    gammaDark: '#51CD80',
    gammaDarker: '#37BE6B',
}

// Reserved
const psi = {}

// Neutral
const omega = {
    omegaLighter: blue[100],
    omegaLight: '#D6F1FF', //colorPreset.blue[200],
    omega: blue[500],
    omegaDark: blue[600],
    omegaDarker: blue[800],
}

const colors = {
    text: gray[600],
    article: gray[700],
    heading: gray[800],

    ...alpha,
    ...beta,
    ...gamma,
    ...psi,
    ...omega,

    successLight: green[100],
    success: green[500],
    errorLight: red[100],
    error: red[500],

    white: white,
    background: `#f8f8f8`,
    contentBg: white,
    headerBg: white,
    footerBg: white,

    mute: gray[300],
    highlight: gray[200],

    modes: {
        dark: {
            // Reverse alpha
            alphaLighter: alpha.alphaDarker,
            alphaLight: alpha.alphaDark,
            alphaDark: alpha.alphaLight,
            alphaDarker: alpha.alphaLighter,

            // Reverse beta
            betaLighter: beta.betaDarker,
            betaLight: beta.betaDark,
            betaDark: beta.betaLight,
            betaDarker: beta.betaLighter,

            // Reverse omega
            omegaLighter: omega.omegaDarker,
            omegaLight: omega.omegaDark,
            omegaDark: omega.omegaLight,
            omegaDarker: omega.omegaLighter,

            text: gray[400],
            article: gray[300],
            heading: gray[200],

            background: gray[900],
            contentBg: gray[800],
            headerBg: gray[800],
            footerBg: gray[800],

            mute: gray[600]
        }
    }
}

export default colors;