/** @jsx jsx */
import {jsx} from 'theme-ui';
import {useState} from "react";

export function useInput(inputProps, select = undefined) {
    const [value, setValue] = useState(select ? select?.defaultOption?.value : undefined);

    let input;
    if (!select) {
        input = <input value={value} onChange={e => setValue(e.target.value)} {...inputProps} />;
    } else {
        const options = select.options;

        // eslint-disable-next-line
        input = <select value={value} onChange={e => setValue(e.target.value)} {...inputProps}>
            {select?.defaultOption?.value === undefined ? <option key="empty" value=""/> : null}
            {options.map(option => <option
                key={option.value}
                value={option.value}>
                {option.text}
            </option>)}
        </select>
    }

    return [value, input];
}

export const calculatorStyles = {
    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        ' label': {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            paddingBottom: '5px'
        },
        ' input::-webkit-calendar-picker-indicator': {
            filter: 'sepia(100)',
        },
        ' input': {
            fontFamily: '"IBM Plex Sans", sans-serif',
            fontSize: '1.25rem',
            width: '100%'
        },
        ' select': {
            fontFamily: '"IBM Plex Sans", sans-serif',
            fontSize: '1.25rem',
            width: '100%'
        }
    },
    formInputs: {
        display: 'flex',
        justifyItems: 'center',
        flexDirection: 'column',
        ' label:not(:first-child)': {
            paddingTop: '1.5rem'
        },
        ' label': {
            textAlign: 'center'
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        fontSize: '1.5rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        borderRadius: '1rem',
        padding: '2rem',
        backgroundColor: 'alphaLighter'
    },
    result: {
        paddingTop: '2rem',
        textAlign: 'center'
    }
};