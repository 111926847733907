import AdPlaceholder, { adsConfig } from "../../../../src/ads";
import DogLitterSizeCalculator from "../../../../content/posts/dog_ownership_and_care/how-many-puppies-do-dogs-have/DogLitterSizeCalculator";
import dogLitterSizes from "../../../../content/posts/dog_ownership_and_care/how-many-puppies-do-dogs-have/dog_litter_sizes.json";
import * as React from 'react';
export default {
  AdPlaceholder,
  adsConfig,
  DogLitterSizeCalculator,
  dogLitterSizes,
  React
};