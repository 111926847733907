import React from "react";

export default React.memo(({...props}) =>
    <div className="dmca-protection-badge-container" {...props}>
        <a href="//www.dmca.com/Protection/Status.aspx?ID=b2f5aa3a-8134-47f0-9d58-69ff67d0e8bd"
           title="DMCA.com Protection Status" className="dmca-badge"> <img
            src="https://images.dmca.com/Badges/dmca_protected_sml_120x.png?ID=b2f5aa3a-8134-47f0-9d58-69ff67d0e8bd"
            alt="DMCA.com Protection Status"/></a>
        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js" defer></script>
    </div>, (prevProps, nextProps) => true);
