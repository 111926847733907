/** @jsx jsx */
import {jsx} from 'theme-ui';
import {globalStyles} from "../../../../src/@elegantstack/flow-ui-layout/Layout/Layout";
import {calculatorStyles, useInput} from "../../../../src/calculator_utils";
import ExternalLink from "../../../../src/links/ExternalLink";
import {amazonAssociatesTag, AmazonImageAndTextProductView} from "../../../../src/amazon";
import {affiliateProducts} from "../../../../src/affiliateProducts";

const DogCrateSizeCalculator = ({dogHeightWeightData}) => {
        let [breedName, breedNameInput] = useInput({
            required: true,
            id: 'breedName',
            name: 'breedName',
        }, {
            options: dogHeightWeightData.map(x => ({text: x.name, value: x.name}))
        });

        let [breedSex, breedSexInput] = useInput({
            required: true,
            id: 'breedSex',
            name: 'breedSex',
        }, {
            options: [{text: 'Male', value: 'male'}, {text: 'Female', value: 'female'}]
        });

        const selectedBreedData = dogHeightWeightData.filter(x => x.name === breedName)?.[0];
        const max_lb = breedSex === 'male' ? selectedBreedData?.max_lb_males : selectedBreedData?.max_lb_females;
        const max_in = breedSex === 'male' ? selectedBreedData?.max_in_males : selectedBreedData?.max_in_females;

        let selectedCrate = undefined;
        if (breedName && breedSex) {
            let product;
            const in_for_cond = max_in;

            if (max_lb <= 10 && in_for_cond < 12) {
                product = {id: 'midwest-wire-crate-22', size: 22}
            } else if (max_lb <= 25 && in_for_cond < 17) {
                product = {id: 'midwest-wire-crate-24', size: 24}
            } else if (max_lb <= 40 && in_for_cond < 19) {
                product = {id: 'midwest-wire-crate-30', size: 30}
            } else if (max_lb <= 70 && in_for_cond < 22) {
                product = {id: 'midwest-wire-crate-36', size: 36}
            } else if (max_lb <= 90 && in_for_cond < 26) {
                product = {id: 'midwest-wire-crate-42', size: 42}
            } else if (max_lb <= 110 && in_for_cond < 28) {
                product = {id: 'midwest-wire-crate-48', size: 48}
            } else {
                product = {id: 'midwest-wire-crate-54', size: 54}
            }

            let link = affiliateProducts?.[product?.id];
            if (link) {
                if (link.includes('?')) {
                    link = `${link}&tag=${amazonAssociatesTag}`
                } else {
                    link = `${link}?tag=${amazonAssociatesTag}`
                }
            }

            selectedCrate = {
                id: product.id,
                name: `Midwest ${product?.size}" Wire Crate`,
                link: link,
                size: product?.size
            }
        }

        return (
            <div sx={{...globalStyles.p, ...calculatorStyles.root}}>
                <form sx={calculatorStyles.form} onSubmit={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                }}>
                    <div sx={{...calculatorStyles.formInputs, ' select': {marginBottom: '20px'}}}>
                        <label htmlFor="breedName">What is your dog's breed?</label>
                        {breedNameInput}
                    </div>
                    <div sx={{...calculatorStyles.formInputs, ' select': {marginBottom: '10px'}}}>
                        <label htmlFor="breedSex">What is the sex of your dog?</label>
                        {breedSexInput}
                    </div>
                </form>

                {breedName && breedSex
                    ?
                    <div sx={calculatorStyles.result}>
                        The average adult {breedSex?.toLowerCase()} {breedName} can weigh up to around {max_lb} lb and be
                        around {max_in}" tall.
                        <br/>
                        <br/>
                        <b>Based on the general guidelines, you should consider getting a {selectedCrate?.size}" wire crate
                            with a divider such as the <ExternalLink href={selectedCrate?.link}
                                                                     paid={true}>{selectedCrate?.name}</ExternalLink>
                            {selectedCrate !== undefined ?
                                <AmazonImageAndTextProductView product={selectedCrate?.id} padTop={true}/>
                                : null
                            }</b>
                    </div>
                    : null
                }
            </div>
        );
    }
;

export default DogCrateSizeCalculator;
