import React from 'react'
import {Box, css} from 'theme-ui'
import {HeaderLogo} from '../Header/Header.Logo'
import DCMABadge from "../../../misc/dmca";

const styles = {
    logo: {
        pb: 1,
        mb: 2,
        mt: [4, 0]
    },
    copyright: {
        pt: 2,
        mb: [2, 4]
    },
    dcma: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: ['15px', '0px']
    }
}

export const FooterLogo = () => (
    <>
        <Box sx={styles.logo}>
            <HeaderLogo grayscale/>
        </Box>
        <Box sx={styles.copyright}>
            © {new Date().getFullYear()}, All Rights Reserved.
        </Box>
        <Box>
            <DCMABadge css={css(styles.dcma)}/>
        </Box>
    </>
)
