const heading = {
    display: `block`,
    color: `heading`,
    fontWeight: `heading`,
    textDecoration: `none`,
    mb: 3
}

const text = {
    heading: {
        ...heading
    },
    h1: {
        ...heading,
        // fontSize: [5, 6, null, 7],
        fontSize: ['2rem', '2.5rem', '2.5rem', '3rem'],
        fontWeight: `bolder`
    },
    h2: {
        ...heading,
        // fontSize: 4
        fontSize: '2rem',
    },
    h3: {
        ...heading,
        // fontSize: 3
        fontSize: '1.75rem',
        fontWeight: 'normal'
    },
    h4: {
        ...heading,
        // fontSize: 2
        fontSize: '1.0rem',
        fontWeight: 'normal'
    },
    h5: {
        ...heading,
        // fontSize: 1
        fontSize: '1.0rem',
        fontWeight: 'normal'
    },
    medium: {
        fontSize: 3,
        mb: 3
    },
    small: {
        fontSize: 1,
        m: 0
    },
    xsmall: {
        fontSize: 0,
        m: 0
    },
    p: {
        fontFamily: `body`,
        mb: 3
    }
}

export default text;