import {
    FaGithub,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
    FaFacebook,
    FaEnvelope,
    FaYoutube,
    FaLink,
    FaGlobe,
    FaPinterest
} from 'react-icons/fa'

const icons = {
    default: {
        Icon: FaLink,
        color: '#718096'
    },
    youtube: {
        Icon: FaYoutube,
        color: 'red',
    },
    pinterest: {
        Icon: FaPinterest,
        color: '#e90625',
    },
    github: {
        Icon: FaGithub,
        color: 'darkslategray'
    },
    twitter: {
        Icon: FaTwitter,
        color: '#1DA1F2'
    },
    instagram: {
        Icon: FaInstagram,
        color: '#C13584'
    },
    linkedin: {
        Icon: FaLinkedinIn,
        color: '#2867B2'
    },
    facebook: {
        Icon: FaFacebook,
        color: '#4267B2'
    },
    email: {
        Icon: FaEnvelope,
        color: '#718096'
    },
    website: {
        Icon: FaGlobe,
        color: 'darkslategray'
    }
}

export default icons;